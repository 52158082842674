<template>
  <!--
    Custom boolean grid filter
  -->
  <div class="input-group">
    <select
      ref="select"
      v-model="computedValue"
      class="form-control mr-2 rounded-right alt-pointer"
      :value="computedValue"
      @change="change"
    >
      <option
        :value="null"
        selected="selected"
        disabled
      >
        {{ $t('pleaseSelectOne') }}
      </option>
      <option
        value="true"
      >
        {{ labelTrue }}
      </option>
      <option value="false">
        {{ labelFalse }}
      </option>
    </select>
    <div
      v-if="computedValue != 'null'"
      class="input-group-append"
    > 
      <button
        class="btn btn-light border rounded"
        @click="reset"
      >
        <font-awesome-icon
          class="gray"
          icon="times"
        />
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BooleanFilter',
  props: {
    labelFalse: {
      type: String,
      required: true
    },
    labelTrue: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: false,
      default () {
        return null;
      }
    }
  },
	computed: {
		computedValue: {
			get: function () {
        return '' + this.value;
			},
			set: function () {},
    }
  },
  methods: {
      change (ev) {
        this.$emit('changeFilter', { operator: 'eq', field: 'placeholder', value: this.$refs.select.value == 'true' ? true : false, syntheticEvent: ev }); //Note: we dont need to speciffy a field as it is coming from the slot scope
      },
      reset (ev) {
        this.computedValue = null;
        this.$emit('changeFilter', { operator: '', field: '', value: '', syntheticEvent: ev });
      }
  }
}
</script>

